<template>
  <div class="box">
    <div class="header">
      <img src="@/img/message2.png" class="message" @click="toMessage" />
      <div class="header_pic" style="overflow: hidden" v-if="token">
        <van-uploader
          multiple
          :max-count="1"
          :after-read="afterRead"
          preview-size="50"
          :deletable="false"
          :max-size="1024 * 1024"
          image-fit="cover"
          @oversize="maxImg"
        >
          <van-image
            width="50"
            height="50"
            round
            fit="cover"
            :src="userInfo.userPhotoFullPath"
          />
        </van-uploader>
        <img src="@/img/my/vip.png" class="vip" v-show="userInfo.vip == 1" />
      </div>

      <img class="header_pic" :src="userInfo.userPhotoFullPath" v-else />
      <div class="header_name" @click="toLogin">
        {{ userInfo.username }}
        <div v-if="userInfo.vip == 1">
          有效期至：{{ userInfo.userVip.expireDate }}
        </div>
        <div v-else>解锁全新功能，发现更多精彩</div>
      </div>
      <div class="top-grid">
        <van-grid icon-size="28px" :border="false">
          <van-grid-item
            :icon="require('@/img/my/grid-1.png')"
            text="我的企业"
            to="companyList"
          />
          <van-grid-item
            :icon="require('@/img/my/grid-2.png')"
            text="融资报告"
            :to="userInfo.vip == 0 ? 'vipNo' : 'report'"
          />
          <van-grid-item
            :icon="require('@/img/my/grid-3.png')"
            text="智能客服"
            to="qa"
          />
          <van-grid-item
            :icon="require('@/img/my/grid-4.png')"
            :text="userInfo.vip == 1 ? '我的vip' : '会员权益'"
            :to="userInfo.vip == 1 ? 'vipInterests' : 'vip'"
          />
        </van-grid>
      </div>
    </div>
    <img src="@/img/my/banner.png" alt="" class="banner" />

    <div class="grid">
      <div class="grid-title">常用工具</div>
      <van-grid icon-size="30px" :border="false">
        <van-grid-item
          :icon="require('@/img/my/grid2-1.png')"
          text="专属顾问"
          to="chat"
        />
        <van-grid-item
          :icon="require('@/img/my/grid2-2.png')"
          text="我的收藏"
          to="collection"
        />
        <van-grid-item
          :icon="require('@/img/my/grid2-3.png')"
          text="分享APP"
          to="shareApp"
        />
        <van-grid-item
          :icon="require('@/img/my/grid2-4.png')"
          text="贷款课程"
          to="course"
        />
        <van-grid-item
          :icon="require('@/img/my/grid2-5.png')"
          text="融资方案"
          to="pipeiChanPin"
        />
        <van-grid-item
          :icon="require('@/img/my/grid2-6.png')"
          to="gzh"
          text="关注公众号"
        />
      </van-grid>
    </div>

    <div class="grid">
      <div class="grid-title">系统功能</div>
      <van-grid icon-size="30px" :border="false">
        <van-grid-item
          :icon="require('@/img/my/grid3-1.png')"
          text="用户反馈"
          to="feedback"
        />
        <van-grid-item
          :icon="require('@/img/my/grid3-2.png')"
          to="about"
          text="关于微企乐"
        />
        <van-grid-item
          :icon="require('@/img/my/grid3-3.png')"
          to="agreement"
          text="隐私协议"
        />
        <van-grid-item
          :icon="require('@/img/my/grid3-4.png')"
          text="账号设置"
          to="seting"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import url from "@/service/url-config";
export default {
  data() {
    return {
      token: "",
      userInfo: {
        username: "登录/注册",
        userPhotoFullPath: require("@/img/my/user-photo.png"),
      },
      fileList: [],
    };
  },
  filters: {
    date(val) {
      let date = new Date(val);
      let y = date.getFullYear(); // 年
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds(); // 秒
      s = s < 10 ? "0" + s : s;
      var timer = y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return timer;
    },
  },
  created() {
    this.token = localStorage.getItem("wql_real_token");
    if (
      localStorage.getItem("wql_real_token") &&
      localStorage.getItem("wql_real_token") != "undefined"
    ) {
      this.$http.get(url.userInfo).then((res) => {
        this.userInfo = res.data;
        if (!this.userInfo.userPhotoFullPath) {
          this.userInfo.userPhotoFullPath = require("@/img/my/user-photo.png");
        }
      });
    }
  },
  methods: {
    toLogin() {
      if (
        !localStorage.getItem("wql_real_token") ||
        localStorage.getItem("wql_real_token") == "undefined"
      ) {
        this.$router.push("login");
      }
    },
    logout() {
      this.$router.push("login");
      window.localStorage.clear();
    },
    afterRead(file) {
      console.log(file);
      const fd = new FormData();
      fd.append("file", file.file);
      this.$http.post(url.photo, fd).then((res) => {
        if (res.code == 200) {
          this.$http.put(url.upUser, {
            userPhoto: res.data.fullPath,
          });
          this.userInfo.userPhotoFullPath =
            res.data.endpoint + res.data.fullPath;
        } else {
          Toast.fail("上传失败");
        }
      });
    },
    toMessage() {
      this.$router.push("message");
    },
    maxImg() {
      Toast.fail("请大小限制在1M");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
  padding-bottom: 80px;
  .header {
    // padding: 15px;
    height: 253px;
    display: flex;
    background: #ffffff;
    background: url("../../img/my/bg.png");
    background-size: 100%;
    position: relative;
    .message {
      width: 21px;
      height: 21px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .header_pic {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 45px;
    margin-left: 10px;
    position: relative;
  }

  .header_name {
    font-size: 21px;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 50px;
  }
  .header_name div {
    font-size: 12px;
    margin-top: 5px;
  }
  .top-grid {
    width: 355px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -178px;
  }
  .banner {
    width: 355px;
    height: 85px;
    margin: 0 auto;
    display: block;
  }
  .grid {
    width: 355px;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 10px;
  }
  .grid-title {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    padding: 14px 0 10px 10px;
  }
  .content {
    width: 100%;
    margin-top: 20px;
    position: relative;
  }

  .toLogin {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .icont {
    color: rgba(184, 191, 204, 1);
  }

  .sec {
    margin-top: 35px;
  }

  .label {
    display: flex;
    align-items: baseline;
  }

  .van-cell-text {
    color: #323232;
    line-height: 20px;
    margin-left: 7px;
  }
  .logout {
    position: fixed;
    bottom: 50px;
    background: #ffffff !important;
    color: #666666 !important;
    border: none;
    font-size: 14px;
  }
  .vip {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
